@import "./variables.scss";

@mixin background($light-theme: true) {
  @if $light-theme {
    background-color: white;
  } @else {
    background-color: black;
  }
}

// Override specific component styles if needed
.p-button {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

// Add more component-specific overrides as needed

.p-inputtext {
  height: 47px;
  border-radius: 6px;
  padding: 11px, 16px, 11px, 16px;
  margin-bottom: 5px;

  &:focus {
    border-width: 2px;
  }
}

.p-dialog {
  @include background($light-theme: true);
  body.dark & {
    @include background($light-theme: false);
  }

  .p-dialog-content {
    // padding-bottom: 0px;
    // overflow: visible !important;
  }
}

.p-select {
  height: 47px;
  display: flex;
  align-items: center;

  width: 100%;
}

.p-password {
  height: 47px;
  display: flex;
  align-items: center;
  width: 100%;
}

.p-paginator {
  .p-paginator-rpp-dropdown {
    width: auto;
  }
}

.action-dialog {
  .p-dialog-header {
    padding: 0px;
  }
}

.p-select {
  margin-bottom: 5px;
}

.action-container {
  width: 100%;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  .action-bar {
    background: $primary;
    color: $white;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 16px;
    padding-right: 20px;
    box-shadow: 0px 4px 15px 0px #00000033;

    .separator {
      height: 50%;
      margin-left: 30px;
      border-color: $separator-light;
      border-left: none;
      margin-right: 30px;
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 20px;
      margin-right: 20px;
      span {
        margin-left: 5px;
      }
    }
  }
}

.error-text {
  color: $failed;
}

//table

.p-paginator {
  position: sticky;
  bottom: 0px;
}

.p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-password-mask-icon {
  cursor: pointer;
}

.p-divider-horizontal:before {
  border-top: 2px solid var(--p-divider-border-color) !important;
}

.p-togglebutton.p-togglebutton-checked {
  color: white !important;

  &::before {
    background-color: $primary !important;
  }
}
