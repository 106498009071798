//radio-btm
.seconize-radio-btn {
  .mat-mdc-radio-button
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle,
  .mat-mdc-radio-button
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: #065fd4 !important;
  }

  .mat-mdc-radio-button
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-width: 6px;
  }

  .mat-mdc-radio-button
    .mdc-radio__native-control:checked
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    transform: scale(0);
  }

  .mat-mdc-radio-button
    .mdc-radio__native-control:enabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: #dde2e4 !important;
  }
  .mat-mdc-radio-button
    .mdc-radio:hover
    .mdc-radio__native-control:not([disabled]):not(:focus)
    ~ .mdc-radio__background::before {
    opacity: 0 !important;
  }
  .mat-mdc-radio-button
    .mdc-radio__native-control:focus
    + .mdc-radio__background::before {
    opacity: 0 !important;
  }
  .mat-mdc-radio-button .mdc-radio {
    padding: 0 !important;
  }
}

//toggle-btn
.seconize-toggle {
  .mdc-switch:enabled .mdc-switch__track::before {
    background: #dde2e4 !important;
  }

  .mdc-switch__track::after,
  .mdc-switch__track::before {
    border: 0;
  }

  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
    background-image: url(../../../assets/icons/close.png);
    fill: none;
    background-repeat: no-repeat;
    background-position: center;
  }

  .mdc-elevation-overlay {
    background-color: #fff !important;
  }

  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
    fill: #959595;
  }

  .mdc-switch:enabled .mdc-switch__track::after {
    background: #37c4bc !important;
  }
}

//checkbox
.seconize-checkbox {
  .mdc-checkbox__background {
    border: 1px solid #959595 !important;
  }

  .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background {
    border-color: #065fd4 !important;
    background-color: #065fd4;
  }

  .mdc-checkbox:hover .mdc-checkbox__ripple {
    opacity: 0 !important;
  }
}

.dark {
  .banner {
    .mat-mdc-checkbox label {
      color: #959595;
    }
  }
}

//input-filed
.seconize-input-filed {
  .mat-mdc-form-field {
    .mdc-notched-outline__notch {
      border-color: #dde2e4 !important;
    }

    .mdc-notched-outline__trailing {
      border-color: #dde2e4 !important;
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
    }

    .mdc-notched-outline__leading {
      border-color: #dde2e4 !important;
      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;
    }
  }

  .mat-mdc-form-field-error {
    font-weight: 700;
  }

  .mat-mdc-select-placeholder {
    color: #959595 !important;
  }

  ::placeholder {
    color: #959595 !important;
  }

  label {
    font-size: 14px;
    color: #959595;
  }

  .mdc-text-field--focused .mdc-notched-outline > * {
    border-color: #065fd4 !important;
  }

  .search-icon {
    color: #959595;
    padding-right: 4px;
  }

  .fld
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-floating-label,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-floating-label--float-above {
    color: #959595 !important;
  }

  .fld {
    .mat-mdc-select-arrow svg {
      display: none;
    }
  }

  .input-dropdown {
    .mat-mdc-select-arrow-wrapper {
      display: none;
    }

    .audit-dropdown-arrow {
      margin-right: 10px;
      font-size: 20px;
      color: #959595;
      -webkit-text-stroke-width: 0.7px;
    }
  }

  .mat-mdc-option .mdc-list-item__primary-text {
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis;
  }
}

.search-field {
  .mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    min-height: 42px !important;
  }
}

//dark-input-filed
.dark {
  .banner {
    .seconize-input-filed {
      .mat-mdc-form-field {
        .mdc-notched-outline__notch {
          border-color: #2e2e2e !important;
        }

        .mdc-notched-outline__trailing {
          border-color: #2e2e2e !important;
          border-top-right-radius: 6px !important;
          border-bottom-right-radius: 6px !important;
        }

        .mdc-notched-outline__leading {
          border-color: #2e2e2e !important;
          border-top-left-radius: 6px !important;
          border-bottom-left-radius: 6px !important;
        }
      }

      .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-text-field__input,
      .mat-mdc-select-placeholder {
        color: #fff !important;
      }

      .mat-mdc-select-placeholder {
        color: #959595 !important;
      }

      label {
        font-size: 14px;
        color: #959595 !important;
      }

      .mdc-text-field--focused .mdc-notched-outline > * {
        border-color: #065fd4 !important;
      }

      .search-icon {
        color: #959595;
        padding-right: 4px;
      }

      .fld
        .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-floating-label,
      .mdc-text-field--outlined:not(.mdc-text-field--disabled)
        .mdc-floating-label--float-above {
        color: #959595 !important;
      }

      .fld {
        .mat-mdc-select-arrow svg {
          display: none;
        }
      }

      .mat-mdc-select .drop {
        .mat-mdc-select-arrow-wrapper {
          display: none;
        }

        .audit-dropdown-arrow {
          margin-right: 10px;
        }
      }

      .input-dropdown {
        .mat-mdc-select-arrow-wrapper {
          display: none;
        }

        .audit-dropdown-arrow {
          margin-right: 10px;
          font-size: 20px;
          color: #959595;
          -webkit-text-stroke-width: 0.7px;
        }

        .mat-mdc-select-value-text {
          color: #fff;
        }
      }
    }
  }
}

//switch tab
.seconize-switch-tab {
  .switch-tabs {
    .mat-mdc-tab:hover .mdc-tab__ripple::before {
      opacity: 0 !important;
    }

    .mat-mdc-tab .mdc-tab__ripple::before {
      background-color: transparent;
    }

    .mat-mdc-tab-ripple {
      display: none;
    }

    .mat-mdc-tab-label-container {
      border-radius: 30px;
      border: 1px solid #e0e0e0;
    }

    .mdc-tab--active {
      border-radius: 30px;
      margin: 4px;
      background-color: #065fd4 !important;
    }

    .mat-mdc-tab .mdc-tab-indicator__content--underline {
      border: none;
    }

    .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
      color: #fff !important;
    }

    .mat-mdc-tab {
      height: 32px !important;
      font-family: "Inter";
    }

    .mdc-tab {
      padding: 0 16px !important;
    }

    .mat-mdc-tab-labels {
      align-items: center;
    }

    .mat-mdc-tab .mdc-tab__text-label {
      font-size: 16px;
      color: #8b8b8b;
      font-weight: 400;
    }

    .mat-mdc-tab:hover .mdc-tab__text-label {
      color: #8b8b8b;
    }
  }
}

//dark switch tab
.dark {
  .banner {
    .seconize-switch-tab {
      .mat-mdc-tab:hover .mdc-tab__ripple::before {
        opacity: 0 !important;
      }

      .switch-tabs {
        .mat-mdc-tab-label-container {
          border-radius: 30px;
          border: 1px solid #2e2e2e;
        }

        .mdc-tab--active {
          border-radius: 30px;
          margin: 4px;
          background-color: #065fd4;
        }

        .mat-mdc-tab .mdc-tab-indicator__content--underline {
          border: none;
        }

        .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
          color: #000000 !important;
        }

        .mat-mdc-tab {
          height: 32px !important;
        }

        .mdc-tab {
          padding: 0 16px !important;
        }

        .mat-mdc-tab-labels {
          align-items: center;
        }

        .mat-mdc-tab .mdc-tab__text-label {
          font-size: 16px;
          color: #8b8b8b;
        }

        .mat-mdc-tab:hover .mdc-tab__text-label {
          color: #8b8b8b;
        }
      }
    }
  }
}

//status badge
.status-box {
  padding: 4px 9px;
  border-radius: 4px;
  height: 28px;
  line-height: 28px;
  width: 91px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
  }
}

.open-status {
  background-color: #629fff;
}

.pending-status {
  background-color: #ffc24c;
}

.rejected-status {
  background-color: #ff7777;
}

.approved-status {
  background-color: #51bfa6;
}

.invalid-status {
  background-color: #818181;
}

.unknow-status {
  background-color: #d9d9d9;
}

// severity
.critical-severity {
  background-color: #d21c14;
}

.high-severity {
  background-color: #ff7777;
}

.medium-severity {
  background-color: #e7ba15;
}

.low-severity {
  background-color: #72c1e1;
}

.very_low-severity {
  background-color: #c3c3c3;
}

//tab scss

.seconize-tab {
  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: #065fd4 !important;
    font: 400 16px / 17.6px "Inter";

    .example-tab-icon {
      color: #065fd4;
    }
  }

  .mdc-tab__text-label {
    color: #959595 !important;
    font: 400 16px / 17.6px "Inter";
  }

  .mat-mdc-tab-label-container {
    border-bottom-color: #dde2e4 !important;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-top-width: 3px !important;
    border-color: #065fd4 !important;
  }
}

//dark tab scss
.dark {
  .banner {
    .seconize-tab {
      .mat-mdc-tab-label-container {
        border-bottom-color: #2e2e2e !important;
      }
    }
  }
}

//tabel
.seconize-tabel {
  .highlight {
    background-color: #e8f2ff;
  }
  .mat-mdc-row:hover {
    background-color: #e8f2ff;
  }

  tbody {
    tr td:first-child {
      border-left: 1px solid #dde2e4;
    }

    tr td:last-child {
      border-right: 1px solid #dde2e4;
    }
  }

  .mat-mdc-table {
    box-shadow: none;
    border-radius: 12px 12px 16px 16px;
    table-layout: fixed;
    width: 100%;
  }

  .mat-mdc-table thead {
    background: #f5f5f5;
  }

  .mat-mdc-table tr th:first-child {
    border-radius: 12px 0px 0px 0px;
  }

  .mat-mdc-table tr th:last-child {
    border-radius: 0px 12px 0px 0px;
  }

  .mat-mdc-header-cell {
    color: #6b6d72;
    font: 400 14px / 20px !important;
    border-bottom: 0;
  }

  .mat-mdc-cell {
    border-bottom-color: #dde2e4;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 16px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 16px;
  }

  .mdc-data-table__row:last-child .mat-mdc-cell {
    border-bottom: 1px solid #dde2e4;
  }
}

.risk-tabel {
  .mat-mdc-table {
    box-shadow: none;
    border-radius: 4px;
    table-layout: fixed;
    width: 100%;
    border: 1px solid #d1dbe8 !important;
    border-radius: 4px;
  }
  thead {
    th {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .mat-mdc-header-cell,
  .mat-mdc-cell {
    border-bottom-color: #d1dbe8;
  }
  .mat-mdc-table tr th:first-child {
    border-radius: 4px;
  }

  .mat-mdc-table tr th:last-child {
    border-radius: 4px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }
  .mdc-data-table__cell {
    font-weight: 600;
  }
}

//dark-tabel
.dark {
  .banner {
    .seconize-tabel {
      .highlight {
        background-color: #2e2e2e;
      }
      .mat-mdc-row:hover {
        background-color: #2e2e2e;
      }
      tbody {
        tr td:first-child {
          border-left: 1px solid #2e2e2e;
        }

        tr td:last-child {
          border-right: 1px solid #2e2e2e;
        }
      }

      .mat-mdc-table {
        box-shadow: none;
        border-radius: 12px 12px 0px 0px;
        table-layout: fixed;
        width: 100%;
        background: none;
      }

      .mat-mdc-table tr th:first-child {
        border-radius: 12px 0px 0px 0px;
      }

      .mat-mdc-table tr th:last-child {
        border-radius: 0px 12px 0px 0px;
      }

      .mat-mdc-cell {
        border-bottom: 1px solid #2e2e2e;
        color: #fff;
      }

      .mat-mdc-header-cell {
        color: #959595;
        font: 400 14px / 20px !important;
      }

      .mat-mdc-table tbody,
      .mat-mdc-table tfoot {
        background: #000000;
        color: #fff;
      }

      .mat-mdc-table thead,
      .mat-mdc-header-cell {
        background: #101010;
        border-bottom-color: #2e2e2e;
      }
    }

    .risk-tabel {
      .mat-mdc-table {
        box-shadow: none;
        border: 1px solid #2e2e2e !important;
        background: transparent;
      }
      .mat-mdc-header-cell,
      .mat-mdc-cell {
        border-bottom-color: #2e2e2e;
        color: #959595;
      }
    }
  }
}

//dark-paginator
.dark {
  .banner {
    .mat-mdc-paginator {
      background-color: #000000;
      color: #fff;
    }

    .mat-mdc-select {
      color: #ffffff;
    }

    .mat-mdc-select-arrow {
      color: #ffffff;
    }

    .mat-mdc-paginator-page-size-select:focus-visible {
      outline: 0;
    }
  }
}

//media query for responsive
@media only screen and (max-width: 1120px) and (min-width: 1023px) {
  .input-dropdown {
    .audit-dropdown-arrow {
      font-size: 14px;
    }
  }

  ::placeholder {
    font-size: 14px;
  }
}
