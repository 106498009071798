//GLOBAL COLOR VARIABLES
$primary: #065fd4;
$secondary: #37c4bc;

// LIGHT VARIABLES
$title-light: #212121;
$body-light: #6b6d72;
$caption-light: #959595;
$stroke-light: #dde2e4;
$bg1-light: #fbfcff;
$bg2-light: #f5f5f5;
$bg3-light: #f5f6f9;
$white: #ffffff;
$separator-light: #a0b5c9;
// DARK VARIABLES
$title-dark: #ffffff;
$body-dark: #adadad;
$caption-dark: #959595;
$stroke-dark: #2e2e2e;
$bg1-dark: #101010;
$bg2-dark: #101010;
$bg3-dark: #101010;
$black: #000000;

// STATUS VARIABLES
$pass: #51bfa6;
$failed: #ff7777;
$planned: #629fff;
$partial: #ffc24c;
$unknown: #d9d9d9;

// SUPPORTIVE VARIABLES
$tags1: #e8f2ff;
$mint: #e8f2ff;
$red-light: #ffdcdc;
$success: #3ed466;

$light-background: $white;
$light-text: $title-light;
$dark-background: $black;
$dark-text: $caption-dark;

@mixin theme-colors($light-theme: true) {
  @if $light-theme {
    background-color: $light-background;
    color: $light-text;
  } @else {
    background-color: $dark-background;
    color: $dark-text;
  }
}

.banner {
  @include theme-colors($light-theme: true);

  body.dark & {
    @include theme-colors($light-theme: false);
  }
}

.popup-header-background {
  background-color: $tags1;
}

@mixin popup-header-background($light-theme: true) {
  @if $light-theme {
    background-color: $tags1;
  } @else {
    background-color: $bg1-dark;
  }
}

@mixin table-border-color($light-theme: true) {
  @if $light-theme {
    border: 1px solid $stroke-light;
  } @else {
    border: 1px solid $stroke-dark;
  }
}

@mixin icon-color($light-theme: true) {
  @if $light-theme {
    color: $black;
  } @else {
    color: $white;
  }
}
