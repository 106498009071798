@import "components/button.scss";
@import "components/form.scss";
@import "components/step.scss";

.lh-21 {
  line-height: 21px;
}

.row {
  display: flex;
  align-items: center;
}
.fw-bold {
  font-weight: bold;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.center-txt {
  text-align: center !important;
}

.link {
  color: #065fd4;
}

.stroke {
  -webkit-text-stroke-width: 0.7px;
}
.stroke-1 {
  -webkit-text-stroke-width: 0.4px;
}

.align-self-strech {
  align-self: stretch;
}
.align-items-baseline {
  align-items: baseline;
}
.align-middle {
  vertical-align: middle;
}
.fw-600 {
  font-weight: 600 !important;
}
.align-items-normal {
  align-items: normal;
}
.mb-1 {
  margin-bottom: 16px;
}
.justify-space-between {
  justify-content: space-between;
}
.disabled-btn {
  opacity: 0.5;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.header-icon {
  color: #959595;
  font-size: 20px;
}

.light-grey {
  color: #959595;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-20 {
  font-size: 20px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.lh-24 {
  line-height: 24px;
}

.blue-txt {
  color: #4f7396 !important;
}

.col-3 {
  width: 30%;
}

.display-flex {
  display: flex;
}

.col-2 {
  width: 20%;
}

.col-6 {
  width: 60%;
}

.col-4 {
  width: 40%;
}

.col-5 {
  width: 50%;
}

.col-1 {
  width: 10%;
}

.gap-1 {
  gap: 16px;
}

.col-9 {
  width: 90%;
}
.col-10 {
  width: 100%;
}

.col-7 {
  width: 70%;
}
.col-8 {
  width: 80%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.justify-content-end {
  justify-content: end;
}
.align-items-end {
  align-items: end;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.align-items-center {
  align-items: center;
}

.postion-relative {
  position: relative;
}

.postion-absolute {
  position: absolute;
}

.border {
  border: 1px solid #dde2e4;
}

.border-right {
  border-right: 1px solid #dde2e4;
}

.border-left {
  border-left: 1px solid #dde2e4;
}

.border-bottom {
  border-bottom: 1px solid #dde2e4;
}

.border-top {
  border-top: 1px solid #dde2e4;
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.mr-6 {
  margin-right: 6px;
}
.fs-14 {
  font-size: 14px !important;
}

.audit-header {
  position: sticky;
  top: 0;
  z-index: 10000;
}
.audit-footer-btn {
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 10000;
}
.audit-body {
  height: calc(100vh - 160px);
  overflow: auto;
}
.mr-2 {
  margin-right: 24px;
}
.dark-grey {
  color: #6b6d72;
}
.info-icon {
  width: 44px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.table-container {
  border-top-left-radius: 12px;
}

.mat-drawer-inner-container {
  .audit-header {
    display: flex;
    justify-content: space-between;
    background: #f5f5f5;
    padding: 24px 30px;
    .heading {
      h2 {
        font: 400 18px / 18px "Inter";
        margin-bottom: 8px;
      }
      h4 {
        margin: 0;
        font: 400 14px / 17.6px "Inter";
        color: #6b6d72;
      }
    }
    .ph {
      color: #959595;
      font-size: 24px;
    }
  }
  .audit-body {
    padding: 0 30px;
    form {
      padding: 24px 0;
    }
  }

  .audit-footer-btn {
    display: flex;
    justify-content: end;
    padding: 16px;
    border-top: 1px solid #eaecf1;
    .primary-btn {
      background-color: #065fd4;
      color: #fff;
      margin-left: 10px;
      margin-left: 10px;
    }
    .primary-btn-disabled {
      background-color: #065fd4;
      opacity: 0.5;
      pointer-events: none;
      color: #fff;
      margin-left: 10px;
      margin-left: 10px;
    }
    .cancel-btn {
      background-color: transparent;
      color: black;
      border: 1px solid #d9d9d9;
      margin-right: 10px;
    }
    .btn-icon-txt {
      border-radius: 4px;
      box-shadow: none;
      height: 42px;
    }

    ::ng-deep .mdc-button__label {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.seconize-dropdown {
  width: 50%;
}
.seconize-dropdown-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.gap-10 {
  gap: 10px !important;
}

.vertically-center {
  .no-data-img {
    background-image: url("../images/compliance/nodata.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 190px;
    height: 180px;
  }

  .no-data-img-dark {
    background-image: url("../images/compliance/inbound-no-img-dark.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 190px;
    height: 180px;
  }

  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .no-data-img-txt {
    text-align: center;

    h1 {
      font: 400 18px / 17.6px "Inter";
      margin-bottom: 12px;
    }

    p {
      line-height: 18px;
      width: 330px;
      text-align: center;
      color: #959595;
      font-size: 16px;
      margin-bottom: 6px;
    }
  }

  .center-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid #e0e0e0;
    border-top: 0;
    height: 100%;
  }
}
.txt-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.txt-black {
  color: #000;
}
.mb-32 {
  margin-bottom: 32px;
}

//filter chips box
.tag-wrapper-filters {
  border-radius: 6px;
  flex-wrap: wrap;

  .chip {
    padding: 4px 12px 4px 12px;
    border-radius: 33px;
    margin: 0 5px;
    background: #e8f2ff;
    border-color: #dde2e4;
  }

  .chip-txt {
    font-size: 14px;
    line-height: 20px;
    color: #212121;
    font-weight: 400;
  }

  .chip-row {
    gap: 6px;
    margin-bottom: 12px;
    flex-wrap: wrap;
    overflow-y: auto;

    .ph {
      font-size: 16px;
      padding-left: 9px;
      cursor: pointer;
    }
  }

  .add-tag-btn {
    position: relative;
    top: 45%;
  }
}

//dark-theme
.dark .banner {
  .border {
    border: 1px solid #2e2e2e;
  }

  .border-right {
    border-right: 1px solid #2e2e2e;
  }
  .border-left {
    border-left: 1px solid #2e2e2e;
  }

  .border-bottom {
    border-bottom: 1px solid #2e2e2e;
  }
  .audit-footer-btn,
  .bg-black {
    background: #000;
  }
  .border-top {
    border-top: 1px solid #2e2e2e;
  }
  .background-transparent {
    background-color: transparent !important;
  }
  .dark-txt {
    color: #adadad !important;
  }
  .dark-heading {
    color: #fff;
  }
  .dark-body {
    color: #959595;
  }
  .no-data-img {
    background-image: url("../images/compliance/inbound-no-img-dark.svg");
  }
  .dark-bg {
    background-color: #101010;
  }

  .mat-drawer-inner-container {
    .audit-header {
      background-color: #2e2e2e;
      color: #fff;
      .heading {
        h4 {
          color: #fff;
        }
      }
      .ph {
        color: #fff;
      }
    }
    label {
      color: #959595;
    }
    .audit-footer-btn {
      border-top: 1px solid #2e2e2e;
      .cancel-btn {
        color: #fff;
        border: 1px solid #2e2e2e;
      }
    }
  }

  .text-primary {
    color: #959595;
  }
}

//header for policy
.common-popup-header {
  background: #f7fafc;
  min-height: 72px;
  height: 100px;
  padding: 0 24px 0 33px;
  color: #0d141c;
  font-weight: 400;
  font-size: 14px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin-bottom: 31px;
}

.dark .banner {
  .common-popup-header {
    background: #101010;
    color: #adadad;
    .ph-x {
      color: #adadad !important;
    }
  }
}

.text-primary {
  // color: #0d141c !important;
  color: var(--text-primary) !important;
}

.text-secondary {
  color: var(--text-secondary) !important;
}

// .text-dark-secondary {
//   color: #141414;
// }

.sze-gray-text {
  color: #4f7396 !important;
}

.pointer {
  cursor: pointer !important;
}

.br-4 {
  border-radius: 4px;
}

.br-16 {
  border-radius: 16px;
}

.custom-dialog {
  label {
    color: #4f7396;
  }
  .error-text {
    height: 12px;
    position: relative;
    bottom: 6px;
  }
}

.list_style_none {
  list-style: none !important;
}
