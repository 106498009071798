@use "@angular/material" as mat;
@use "./assets/styles/themes/m3-theme.scss" as m3-theme;
@use "./assets/styles/base/typography.scss" as typography;

// PrimeNG Icons
@import "primeicons/primeicons.css";

// IMPORTS
@import "./assets/styles/helpers/variables.scss";
@import "./assets/styles/helpers/mixin.scss";
@import "./assets/styles/components/button.scss";
@import "./assets/styles/components/form.scss";

// prime ng custom theme
@import "./assets/styles/helpers/custom-primeng-theme.scss";

@include mat.core();

// @font-face {
//   font-family: "Lato";
//   src: url("./assets/fonts/Lato-Regular.ttf") format("truetype");
//   font-display: swap;
// }

// @font-face {
//   font-family: "Source Sans 3";
//   src: url("./assets/fonts/SourceSans3-Regular.ttf") format("truetype");
//   font-display: swap;
// }

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_18pt-Regular.ttf") format("truetype");
  font-display: swap;
  font-size: 14px;
  font-weight: normal;
}

// Applying the theme to all components
:root {
  @include mat.all-component-themes(m3-theme.$light-theme);

  @include mat.all-component-typographies(
    typography.$mat-typography-primary-config
  );

  // --weak-color: #ff0000;
  // --medium-color: #ffcc00;
  // --strong-color: #00cc00;

  --cm-logo-bg-light: #f6f6f6;
  --cm-logo-bg-dark: #2b2b2b;

  --white-varient-2: #f3f3f3;
  --black-varient-2: #2e2e2e;

  --white-varient-1: #f7fafc;
  --black-varient-1: #1a202c;

  --text-primary-light: #0d141c;
  --text-primary-dark: #f6f6f6;

  --text-secondary-light: #141414;

  --shadow-light: #edf7fe;
  --card-bg-dark: #18181b;

  --black: #000000;
  --white: #ffffff;

  --gray-text-light: #4b4b4b;
  --gray-text-dark: #959595;

  --expanded-row-light: #fbfbfb;
  --expanded-row-dark: #1b1b1b;

  --ai-chat-light-bg: #edf0f5;
  --ai-chat-light-c: #152f42;

  --ai-chat-dark-bg: #2b2f36;
  --ai-chat-dark-c: #e4e8ec;
}

.light {
  --cm-logo-bg: var(--cm-logo-bg-light) !important;
  --border-color: var(--white-varient-2) !important;
  --mini-card-bg: var(--white-varient-1) !important;
  --section-bg: var(--white-varient-1) !important;
  // --header-bg: #ffffff !important;
  --text-primary: var(--text-primary-light) !important;

  --shadow-color: var(--shadow-light) !important;
  --card-bg: var(--white) !important;

  --page-bg: var(--white-varient-1) !important;

  --gray-text: var(--gray-text-light) !important;

  --text-secondary: var(--text-secondary-light) !important;

  --expanded-row: var(--expanded-row-light) !important;

  --ai-chat-bg: var(--ai-chat-light-bg) !important;
  --ai-chat-c: var(--ai-chat-light-c) !important;
}

.dark {
  --cm-logo-bg: var(--cm-logo-bg-dark) !important;
  --border-color: var(--black-varient-2) !important;
  --mini-card-bg: var(--black-varient-1) !important;
  --section-bg: var(--black-varient-1) !important;
  // --header-bg: #2e2e2e !important;
  --text-primary: var(--text-primary-dark) !important;

  --shadow-color: #000000 !important;
  --card-bg: var(--card-bg-dark) !important;

  --page-bg: var(--black) !important;

  --gray-text: var(--gray-text-dark) !important;

  --text-secondary: var(--white) !important;

  --expanded-row: var(--expanded-row-dark) !important;

  --ai-chat-bg: var(--ai-chat-dark-bg) !important;
  --ai-chat-c: var(--ai-chat-dark-c) !important;
}

html,
body {
  height: 100%;
  // background: var(--page-bg) !important;
}

body {
  margin: 0;
}

.body .dark {
  ::ng-deep .main {
    mat-drawer {
      background-color: #000 !important;
    }
  }
}

.powered-by {
  position: fixed;
  bottom: 0;
  height: 35px;
  width: 205px;
  border-radius: 8px;
  background-color: #f2f2f2;
  color: #959595;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.banner {
  .powered-by {
    background-color: #2e2e2e;
    color: #d9eaff;
  }
}

//select overlay
.cdk-overlay-container .cdk-overlay-pane {
  .seconize-select-dropdown {
    background-color: #fff;

    //select active
    .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
      background-color: #e8f2ff;
    }

    .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
      background-color: #e8f2ff;
    }

    .mat-pseudo-checkbox {
      display: none;
    }
  }
}

//input dropdown
.cdk-overlay-container .cdk-overlay-pane {
  .seconize-input-dropdown {
    background-color: #fff !important;

    //select active
    .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
      background-color: #e8f2ff;
      flex-flow: row-reverse;
    }

    .mat-mdc-option .mat-pseudo-checkbox-minimal {
      margin-left: 0;
      margin-right: 6px;
    }

    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
      .mdc-list-item__primary-text {
      font-weight: 600;
      margin-left: 0;
    }

    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
        .mat-mdc-option-multiple
      ) {
      background-color: #e8f2ff !important;
    }

    div.mat-mdc-select-panel {
      background-color: #fff !important;
    }

    .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
      background-color: #e8f2ff;
    }

    .mat-mdc-option .mdc-list-item__primary-text {
      margin-left: 18px;
    }
  }
}

//dark-input-overlay
.cdk-overlay-container {
  .seconize-dark-input-dropdown {
    background-color: #101010 !important;

    //select active
    .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
      background-color: #212121;
      flex-flow: row-reverse;
    }

    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
      .mdc-list-item__primary-text {
      color: #959595;
    }

    .mat-mdc-option .mat-pseudo-checkbox-minimal {
      margin-left: 0;
      margin-right: 6px;
    }

    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
      .mdc-list-item__primary-text {
      font-weight: 600;
      margin-left: 0;
    }

    .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
      background-color: #2e2e2e;
    }

    .mat-mdc-option .mdc-list-item__primary-text {
      margin-left: 18px;
    }

    .mat-mdc-option,
    .mat-mdc-select-value-text {
      color: #fff;
    }
  }
}

.dark {
  .cdk-overlay-container .cdk-overlay-pane {
    .mat-datepicker-content {
      box-shadow: 0px 10px 15px -3px #0000001a !important;
      background-color: #101010 !important;
      color: #fff;
    }
    .mat-calendar-body-cell-content.mat-focus-indicator,
    .mat-calendar-table-header th {
      color: #fff !important;
    }
  }
}

//datepicker
.cdk-overlay-container .cdk-overlay-pane .mat-datepicker-content {
  box-shadow: 0px 10px 15px -3px #0000001a !important;
  background-color: white;
}

//setting overlay
.cdk-overlay-container .custom-modalbox {
  min-width: 900px !important;

  .mat-mdc-dialog-surface {
    border-radius: 4px;
    padding: 16px;
    // height: calc(100vh - 44px);
  }
}

.dark .cdk-overlay-container .custom-modalbox {
  .mat-mdc-dialog-surface {
    border-radius: 4px;
    background-color: #101010;
    color: #fff;
    padding: 16px;

    .seconize-tab {
      .mat-mdc-tab-header {
        top: 0;
        z-index: 1000;
        position: sticky;
        position: -webkit-sticky;
        background-color: #101010;
        padding-left: 24px;
      }
    }
  }
}

::ng-deep #g_id_onload .nsm7Bb-HzV7m-LgbsSe {
  width: 458px !important;
  border-radius: 6px !important;
  height: 48px !important;
}

//profile overlay
.cdk-overlay-container .cdk-overlay-pane .custom__menu {
  width: 400px;
  background-color: #fff !important;

  .seconize-btn-toggle {
    .mat-button-toggle .mat-pseudo-checkbox {
      display: none;
    }

    .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
      background-color: transparent !important;
    }

    .sun-bg {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: 50%;
      position: relative;
      top: 4px;
      right: 6px;
    }

    .moon-bg {
      position: relative;
      top: 1px;
    }

    .ph-moon {
      color: #212121;
      font-size: 20px;
    }
    .ph-sun {
      font-size: 20px;
    }

    .mat-button-toggle-group {
      border: 0;
      background: #dde2e4;
      height: 33px;
    }

    .mat-button-toggle-group-appearance-standard
      .mat-button-toggle-appearance-standard
      + .mat-button-toggle-appearance-standard {
      border-left: 0;
    }

    .mat-button-toggle {
      width: 35px;
    }
  }

  .mat-mdc-menu-item:not([disabled]):hover {
    background-color: #e8f2ff !important;
  }
}

.proflie-menu {
  width: 255px;

  .seconize-btn-toggle {
    .mat-button-toggle .mat-pseudo-checkbox {
      display: none;
    }

    .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
      background-color: transparent !important;
    }

    .sun-bg {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: 50%;
      position: relative;
      top: 4px;
      right: 6px;
    }

    .moon-bg {
      position: relative;
      top: 0px;
      left: -4px;
    }

    .ph-moon {
      color: #212121;
      font-size: 20px;
    }
    .ph-sun {
      font-size: 20px;
    }

    .mat-button-toggle-group {
      border: 0;
      background: #dde2e4;
      height: 33px;
    }

    .mat-button-toggle-group-appearance-standard
      .mat-button-toggle-appearance-standard
      + .mat-button-toggle-appearance-standard {
      border-left: 0;
    }

    .mat-button-toggle {
      width: 35px;
    }
  }

  .mat-mdc-menu-item:not([disabled]):hover {
    background-color: #e8f2ff !important;
  }
}

//profile dark-overlay
.cdk-overlay-container .cdk-overlay-pane .dark_custom__menu {
  width: 400px;
  background-color: #101010 !important;
  color: #fff;

  .dark-heading {
    color: #fff !important;
  }

  .border-bottom {
    border-bottom: 1px solid #2e2e2e;
  }

  .seconize-btn-toggle {
    .mat-button-toggle .mat-pseudo-checkbox {
      display: none;
    }

    .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
      background-color: transparent !important;
      width: 35px;
    }

    .mat-button-toggle-group {
      border: 0;
      background: #212121;
    }

    .mat-button-toggle-group-appearance-standard
      .mat-button-toggle-appearance-standard
      + .mat-button-toggle-appearance-standard {
      border-left: 0;
    }

    .moon-bg {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: 50%;
      position: relative;
      top: 8px;
      right: 6px;
    }

    .ph-sun {
      color: #fff;
    }

    .sun-bg {
      position: relative;
      top: -2;
    }
  }

  .seconize-input-filed {
    .mat-mdc-form-field {
      .mdc-notched-outline__notch {
        border-color: #2e2e2e !important;
      }

      .mdc-notched-outline__trailing {
        border-color: #2e2e2e !important;
      }

      .mdc-notched-outline__leading {
        border-color: #2e2e2e !important;
      }
    }
  }
}

.cdk-overlay-container .cdk-overlay-pane .project-menu {
  background-color: #fff !important;

  .mat-mdc-menu-item {
    border-bottom: 1px solid #dde2e4 !important;
  }
}

.cdk-global-scrollblock {
  overflow: auto !important;
}

//mat-dialog
.cdk-overlay-container .fiter-modalbox {
  border-radius: 6px;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin-bottom: 0 !important;
    }

    margin-bottom: 25px;
  }

  .audit-footer-btn {
    width: 100%;
    display: flex;
    justify-content: end;

    .primary-btn {
      background-color: #065fd4;
      color: #fff;
      margin-left: 10px;
    }

    .cancel-btn {
      background-color: transparent;
      color: black;
      border: 1px solid #d9d9d9;
      margin-right: 10px;
    }

    .btn-icon-txt {
      border-radius: 4px;
      box-shadow: none;
      height: 42px;
    }

    ::ng-deep .mdc-button__label {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .activity-filed {
    .mdc-notched-outline__leading {
      border-color: transparent !important;
    }

    .mdc-notched-outline__notch {
      border-color: transparent !important;
    }

    .mdc-notched-outline__trailing {
      border-color: transparent !important;
    }
  }

  // .mat-mdc-dialog-content {
  //   color: #ADADAD;
  // }
  .mat-mdc-dialog-surface {
    border-radius: 6px !important;
  }

  .mat-mdc-dialog-actions {
    border-top: 0 !important;
  }

  .mat-mdc-select-arrow-wrapper {
    display: none;
  }

  .audit-dropdown-arrow {
    margin-right: 10px;
    font-size: 20px;
  }

  .mat-mdc-form-field {
    .mdc-notched-outline__notch {
      border-color: #dde2e4;
    }

    .mdc-notched-outline__trailing {
      border-color: #dde2e4;
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
    }

    .mdc-notched-outline__leading {
      border-color: #dde2e4;
      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;
    }

    .mdc-text-field--outlined .mat-mdc-form-field-infix,
    .mdc-text-field--no-label .mat-mdc-form-field-infix {
      padding-top: 6px !important;
      padding-bottom: 6px;
      min-height: 32px;
    }
  }

  .mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: 48px;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-select-placeholder {
    color: #959595 !important;
  }

  .dark-box-shadow {
    box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075),
      /* shadow ring 👇 */ 0 0 0 1px hsla(0, 0%, 0%, 0.05),
      /* multiple soft shadows 👇 */ 0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
      0 0.9px 1.5px hsla(0, 0%, 0%, 0.045), 0 3.5px 6px hsla(0, 0%, 0%, 0.09);
  }
}

.dark .cdk-overlay-container .fiter-modalbox {
  .mat-mdc-dialog-surface {
    background-color: #101010;
    border-radius: 6px !important;
  }

  .modal-header {
    color: #fff;
  }

  .dark-black {
    background: #000 !important;
  }

  .border-dark {
    border: 1px solid #2e2e2e;
  }

  label,
  .p-area {
    color: #959595;
  }

  .ph {
    color: #fff;
  }

  .grey-box {
    background-color: #000000;

    .table-desc {
      color: #ffffff;
    }
  }

  .disable-file-upload,
  .upload-section {
    background-color: transparent !important;
    border: 2px dashed #2e2e2e !important;
  }

  .audit-footer-btn {
    background-color: #101010;

    .cancel-btn {
      color: #fff;
      border: 1px solid #2e2e2e;
    }
  }

  .mat-mdc-form-field {
    .mdc-notched-outline__notch {
      border-color: #2e2e2e !important;
    }

    .mdc-notched-outline__trailing {
      border-color: #2e2e2e !important;
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
    }

    .mdc-notched-outline__leading {
      border-color: #2e2e2e !important;
      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;
    }
  }
}

.dark .cdk-overlay-container .policy-modalbox {
  .modal-header {
    background: #000000;
    color: #959595;
  }
  .ph {
    color: #959595;
  }
}

.dark .banner {
  .risk-footer {
    background-color: transparent !important;
  }
}
//mat-auto-search-overlay
.cdk-overlay-container .cdk-overlay-pane .auto-dropdown {
  .mat-mdc-option:focus.mdc-list-item,
  .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
    background-color: #fff !important;
    // background-color: #e8f2ff;
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
      .mat-mdc-option-multiple
    ),
  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: #fff;
    // background-color: #e8f2ff;
  }
}

.dark .cdk-overlay-container .cdk-overlay-pane .auto-dropdown {
  background-color: #000 !important;

  .mat-mdc-option:focus.mdc-list-item,
  .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
    background-color: #2e2e2e;
  }

  .mat-mdc-option {
    color: #fff;
  }

  .mat-mdc-option-active {
    color: #959595;
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
      .mat-mdc-option-multiple
    ),
  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: #2e2e2e;
  }
}

//auto-dropdown overlay
.cdk-overlay-container .cdk-overlay-pane .auto-dropdown {
  background-color: #fff;
}

//wizard-dialog overlay
.cdk-overlay-container .wizard-dialog {
  // .mat-mdc-dialog-container .mat-mdc-dialog-content {
  //   padding-top: 16px;
  //   padding-bottom: 16px;
  // }
  .mdc-dialog__surface {
    border-radius: 8px;
    width: 460px;
    box-shadow: 0px 4px 4px 0px #d9eaff;
  }
}

//tooltip overlay
.cdk-overlay-container .cdk-overlay-pane {
  .mat-mdc-tooltip-surface {
    background-color: #fff;
    color: #6b6d72;
    box-shadow: 0px 4px 8px 0px #00000029;
    border-radius: 0;
    font-size: 14px;
  }

  .tooltip-pd {
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 21px;
      top: -8px;
      border-bottom: 12px solid #fff;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
    }
  }
}

//tooltip overlay
// .cdk-overlay-container .cdk-overlay-pane  {
//   .mat-mdc-tooltip-surface {
//     background-color: #fff;
//     color: #6B6D72;
//     box-shadow: 0px 4px 8px 0px #00000029;
//     border-radius: 0;
//     font-size: 14px;
//     padding: 17px;
//     white-space: pre-line;
//   }
//   .planner-tooltip {
//     &::after {
//       content: '';
//       position: absolute !important;
//       left: 31px !important;
//       top: 76px !important;
//       width: 0  !important;
//       height: 0 !important;
//       border-left: 20px solid transparent !important;
//       border-right: 20px solid transparent !important;
//       border-top: 20px solid #fff !important;
//       clear: both !important;
//     }
//   }
// }

.mdc-tooltip {
  position: relative;

  .mat-mdc-tooltip-surface {
    background-color: #fff;
    color: #6b6d72;
    box-shadow: 0px 4px 8px 0px #00000029;
    border-radius: 0;
    font-size: 14px;
    padding: 14px;
    border-radius: 4px;
  }

  &::after {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid #fff;
  }

  &.below {
    overflow: initial;

    // margin-top: 1rem;
    &:after {
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0);
    }
  }

  &.above {
    overflow: initial;

    // margin-bottom: 1rem;
    &:after {
      bottom: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(180deg);
    }
  }

  &.right {
    overflow: initial;
    margin-left: 1rem;

    &:after {
      left: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(270deg);
    }
  }

  &.left {
    overflow: initial;
    margin-right: 1rem;

    &:after {
      right: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(90deg);
    }
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid #fff;
}

::-moz-range-thumb {
  background-image: url("https://dev2.seconize.co/assets/img/brand/shape-white.png");
}

.dark
  .cdk-overlay-container
  .cdk-overlay-pane
  .mat-mdc-select-panel-above
  div.mat-mdc-select-panel {
  background-color: red !important;
}

.admin-page-toggles {
  .mat-mdc-tab {
    height: 30px !important;
    letter-spacing: normal !important;
  }

  .mdc-tab--active {
    .mdc-tab__text-label {
      color: #065fd4 !important;
    }
  }

  // .mat-mdc-tab-label-container {
  //   border: none !important
  // }
}

.mb-35px {
  margin-bottom: 35px !important;
}

// --------------------- Prime NG ---------------------

// ----- Table -----
.p-datatable-table-container {
  // border: 1px solid #D1DBE8 !important;
  @include table-border-color($light-theme: true);

  body.dark & {
    @include table-border-color($light-theme: false);
  }

  // border-right: none !important;
  border-radius: 4px;
  font-family: "Inter" !important;
}

.p-datatable {
  // margin-top: 1rem !important;
  // border-top: 1px solid #ebebeb;

  .p-datatable-table {
    margin-top: 0 !important;
  }

  .show-on-hover {
    visibility: hidden;
  }

  .visible-when-selected {
    visibility: visible !important;
  }

  td {
    white-space: normal !important;
    word-wrap: break-word;
    word-break: break-word;
  }

  th {
    white-space: nowrap;

    &:hover {
      .p-datatable-column-resizer {
        border-right: 1.5px solid #065fd4;
      }

      .show-on-hover {
        visibility: visible;
      }
    }
  }
}

.p-datatable-filter-overlay-popover {
  .p-datatable-filter-operator,
  .p-datatable-filter-constraint-dropdown,
  .p-datatable-filter-add-rule-button {
    display: none;
  }
}

// ----- Dialog -----

.p-overlay-mask {
  z-index: 1103 !important ;
}

.p-dialog-header-actions {
  width: 100%;
}

.popup-header {
  @include popup-header-background($light-theme: true);

  body.dark & {
    @include theme-colors($light-theme: false);
  }

  height: 50px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;

  .popup-header-text {
    .primary {
      font-size: 16px;
      font-weight: bold;
    }

    .secondary {
      font-size: 10px;
      color: gray;
    }
  }

  .popup-header-close-btn {
    @include icon-color($light-theme: true);

    body.dark & {
      @include icon-color($light-theme: false);
    }

    cursor: pointer;
    font-size: 20px;
  }
}

.p-footer {
  @include background($light-theme: true);

  body.dark & {
    @include background($light-theme: false);
  }

  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  padding-right: 0px !important;
}

.p-inputtext,
.p-inputwrapper {
  width: 100%;
}

.mat-stepper-horizontal {
  @include background($light-theme: true);

  body.dark & {
    @include background($light-theme: false);
  }
}

.seconize-searchbar {
  width: 400px !important;
}

// ----- Flex Layout -----
.flex-row-end-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gap-1 {
  gap: 1rem;
}

// ----- Table and Pagination -----
.p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

// comment the bellow once the issue is fixed
.top-position {
  .p-overlay {
    top: -154px !important;
  }
}

.p-paginator-rpp-dropdown {
  .p-overlay {
    top: -154px !important;
  }
}

// .table-header {
//   display: flex;
//   justify-content: space-between;
// }

.p-datatable {
  .table-header {
    display: flex;
    justify-content: space-between;
    height: 50px;
  }

  .p-datatable-header {
    padding: 1rem;
    text-align: left;
  }

  .p-datatable-thead {
    height: 50px;
  }

  th {
    .p-icon {
      height: 10px !important;
      width: 25px !important;
    }
  }

  .p-tag {
    white-space: nowrap;
    height: 22px;
    font-weight: 400;

    .p-tag-label {
      color: #fff !important;
    }
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-select-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

p-table {
  .p-datatable-emptymessage {
    height: calc(100vh - 270px);
    &:hover {
      background: transparent !important;
    }
  }
}

.p-w-100 {
  width: 100%;
}

/* Responsive */
.p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 960px) {
  .p-datatable {
    .p-datatable-thead > tr > th,
    .p-datatable-tfoot > tr > td {
      display: none !important;
    }

    .p-datatable-tbody > tr {
      border-bottom: 1px solid var(--layer-2);

      > td {
        text-align: left;
        width: 100%;
        display: flex;
        align-items: center;
        border: 0 none;

        .p-column-title {
          min-width: 30%;
          display: inline-block;
          font-weight: bold;
        }

        p-progressbar {
          width: 100%;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
        }
      }
    }
  }
}

.align-top {
  vertical-align: top !important;
}

.close-icon {
  position: absolute;
  right: 18px;
  padding: 10px;
  cursor: pointer;
}

.justify-content-between {
  justify-content: space-between !important;
}

.mr16 {
  margin-right: 16px !important;
}

.search-field {
  width: 400px;

  .mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 40px;
  }

  .p-inputtext {
    background-color: #f0f2f5 !important;
    border-radius: 12px !important;
    height: 40px;
    width: 400px;
    border: none !important;
  }

  .p-inputicon {
    color: #959595 !important;
    padding-right: 16px !important;
  }

  .mat-mdc-text-field-wrapper {
    background-color: #f0f2f5 !important;
    border-radius: 12px !important;
    height: 40px;
  }

  .search-icon {
    color: #959595 !important;
    padding-right: 16px !important;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing {
    border-width: 0 !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

// Asset Type Cards
.asset-type-card {
  @include table-border-color($light-theme: true);

  body.dark & {
    @include table-border-color($light-theme: false);
  }
}

// Select Overlay
.p-select-overlay {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

div.mat-mdc-autocomplete-panel {
  background-color: white !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
    .mat-mdc-option-multiple
  ) {
  background-color: white !important;
}

.img-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #f0f2f5;
  border-radius: 8px;
}
.mb-10px {
  margin-bottom: 10px;
}

// .custom-cascade-panel .ui-cascadeselect-items {
//   display: none; /* Hide initially */
// }

// .custom-cascade-panel
//   .ui-cascadeselect-item-group:hover
//   .ui-cascadeselect-items {
//   display: block !important; /* Show child (state) list on hover */
//   position: absolute;
//   top: 0;
//   left: 100%; /* Position the states list to the right */
//   z-index: 1000;
// }

// .ui-cascadeselect-item-group {
//   position: relative;
// }

// dark mode changes

.dark {
  .search-field {
    .p-inputtext,
    .mat-mdc-text-field-wrapper {
      background-color: #2c2d2f !important;
      // color: #7a7a7a !important;
    }
  }

  .p-menu-item-link-active {
    background: #2a2a2a !important; // Administartion Menu Active Background
  }
}

.primary-text {
  color: #065fd4 !important;
}
// input:-webkit-autofill {
//   animation-name: autofill;
//   animation-duration: 5000s;
// }

// @keyframes autofill {
//   to {
//     color: #000;
//     background: #fff;
//   }
// }

// ::ng-deeps {
//   .social-login-buttons {
//     button {
//       display: flex;
//       align-items: center;
//       margin-bottom: 10px;
//       padding: 10px;
//       border: none;
//       background-color: #fff;
//       cursor: pointer;
//       font-size: 16px;

//       img {
//         margin-right: 10px;
//       }
//     }

//     asl-google-signin-button {
//       .nsm7Bb-HzV7m-LgbsSe-BPrWId {
//         &:before {
//           content: "Google";
//           display: inline-block;
//           margin-right: 10px;
//         }

//         span {
//           display: none;
//         }
//       }
//     }
//   }
// }

// ::ng-deep {
//   .nsm7Bb-HzV7m-LgbsSe-BPrWId {
//     color: green !important;
//   }

//   .nsm7Bb-HzV7m-LgbsSe {
//     border: 2px red solid !important;
//   }
// }

// .nsm7Bb-HzV7m-LgbsSe-BPrWId {
//   // color: green !important;
//   position: relative;
//   /* Hide the existing text */
//   color: transparent !important;
// }

// .nsm7Bb-HzV7m-LgbsSe-BPrWId::before {
//   content: "Google";
//   display: inline-block;
//   // color: green;
//   position: absolute;
//   top: 0;
//   left: 0;

//   font-family: "Google Sans", arial, sans-serif;
//   font-size: 14px;
//   // font-weight: 600;
//   letter-spacing: 0.25px;
//   color: #3c4043;
//   cursor: pointer;
//   background-color: #fff;
// }

// .google-btn {
//   .nsm7Bb-HzV7m-LgbsSe {
//     border: 2px red solid !important;
//   }
//   .nsm7Bb-HzV7m-LgbsSe-BPrWId {
//     color: green !important;
//   }
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
input:-internal-autofill-previewed {
  background-color: white !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.custom-spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite,
    custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
  100%,
  0% {
    stroke: #2e7dc2 !important;
  }
  40% {
    stroke: #2e7dc2 !important;
  }
  66% {
    stroke: #30d093 !important;
  }

  90% {
    stroke: #30d093 !important;
  }
}

@keyframes custom-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
.hover-link:hover {
  cursor: pointer !important;
  color: #065fd4 !important;
  text-decoration: underline !important;
}

.no-wrap {
  white-space: nowrap !important;
}

// .page-container {
//   width: 100%;
//   height: 100%;
// }
