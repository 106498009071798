// btn-scss
.seconize-btn {
  .primary-btn {
    background-color: #065fd4 !important;
    color: #fff;
    font-family: "Inter" !important;
  }

  .danger-btn {
    background-color: #ff4444 !important;
    color: #fff;
    font-family: "Inter" !important;
  }

  .btn {
    border-radius: 6px !important;
  }
  .secondary-outline {
    border-color: #d9d9d9 !important;
    color: #212121;
  }

  .reset-btn {
    border-color: #959595;
    color: #959595;
  }
  .btn-icon-txt {
    border-radius: 4px !important;
    box-shadow: none;
    height: 42px;
  }
  .btn-icon-outline {
    background-color: transparent;
    border: 1px solid #065fd4;
    color: #065fd4;
  }
  .btn-secondary-outline {
    border: 1px solid #dde2e4;
    color: #000;
    background-color: transparent;
  }
  .btn-icon {
    border-radius: 4px !important;
    box-shadow: none;
    background-color: transparent;
    border: 1px solid #dde2e4;
  }

  .btn-icon-txt {
    .mat-icon {
      margin-right: 6px;
    }
  }
  .mdc-button__label {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
  }
  .mat-mdc-mini-fab:active,
  .mat-mdc-mini-fab:focus:active,
  .mat-mdc-mini-fab:focus {
    box-shadow: none;
  }
  .mat-mdc-mini-fab.cdk-program-focused
    .mat-mdc-button-persistent-ripple::before,
  .mat-mdc-mini-fab.cdk-keyboard-focused
    .mat-mdc-button-persistent-ripple::before,
  .mat-mdc-mini-fab.mat-mdc-button-disabled-interactive:focus
    .mat-mdc-button-persistent-ripple::before {
    opacity: 0 !important;
  }
  .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 0;
  }
  .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 0;
  }
  .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
    opacity: 0;
  }
  ::ng-deep .mat-mdc-mini-fab:not(.mdc-fab--extended) .mdc-fab__ripple {
    border-radius: 0;
  }
  .mat-mdc-mini-fab:hover {
    background-color: #e8f2ff;
    box-shadow: none;
  }
  .mat-mdc-extended-fab:focus {
    box-shadow: none !important;
  }
  .mat-mdc-mini-fab:hover .mat-icon {
    color: #065fd4;
  }

  .primary-icon {
    color: #065fd4;
  }
  .mat-mdc-extended-fab:hover {
    box-shadow: none;
  }
}

//dark theme
.dark {
  .banner {
    .seconize-btn {
      .btn-icon {
        border-radius: 4px !important;
        box-shadow: none;
        background-color: transparent;
        border: 1px solid #2e2e2e;
      }
    }
    .btn-secondary-outline {
      color: #fff;
      border: 1px solid #2e2e2e;
      background-color: transparent;
    }
  }
}

//media query for responsive
// @media only screen and (max-width: 1120px) and (min-width: 1023px) {
//     .seconize-btn {
//         .btn-icon-txt {
//             height: 33px;
//         }
//     }

// }
