.min-w {
    min-width: 135px;
  }
  .draft-step {
    margin-top: 10px;
  }

  .step {
    color: #4f7396;
    line-height: 24px;
    font-size: 12px;
    font-weight: 600;
  }
  .object-fit-contain {
    object-fit: contain;
  }
  .w-149 {
    width: 135px;
  }
  .draft-box {
    background: linear-gradient(358.96deg, #ebf1f5 0.81%, #f7fafc 99.02%);
    border-radius: 16px;
    padding: 30px 4px 33px 14px;
  }

  //media query for responsive 125%
@media only screen and (max-width: 1110px) and (min-width: 1023px) {
    .mat-column-actions {
      width: 32% !important;
    }
    .tabel-icons {
      font-size: 16px !important;
    }
  
    .step-txt {
      font-size: 12px;
    }
    .w-149 {
      width: 100px;
    }
    .min-w {
      min-width: 100px;
    }
    .step {
      margin-right: 12px !important;
    }
    .step-txt {
      font-size: 12px;
    }
    .object-fit-contain {
      width: 40px;
    }
    .w-40 {
      width: 107px !important;
    }
    .img-draft {
      width: 32px;
      height: 32px;
    }
  }
  
  //media query for responsive for 110%
  @media only screen and (min-width: 1239px) and (max-width: 1300px) {
    .w-149 {
      width: 120px;
    }
    .min-w {
      min-width: 120px;
    }
  }
  
  //media query for responsive for 80%
  @media only screen and (min-width: 1600px) and (max-width: 2400px) {
    .w-149 {
      width: 150px;
    }
    .min-w {
      min-width: 150px;
    }
  }